// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_graphql: "https://cbmm-dimos-service.duka.dev/graphql",
  base_url: "https://cbmm-dimos-service.duka.dev/api/v1",
  
  client_id: '7e7bc7fe-a4af-4fb9-a26a-d8fdf5ba2f81',
  tenant_id: 'df1f457e-71df-415c-b8cc-0796095281a9',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
